import React, { useEffect, useRef, useState } from "react"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useStaticQuery, graphql } from "gatsby"

import About from "../components/about"
import ClareImage from "../components/clareImage"
import Layout from "../components/layout"
import BookStuff from "../components/bookStuff"
import Contact from "../components/contact"
import Press from "../components/press"
import Writing from "../components/writing"
import SEO from "../components/seo"
import colors from "../styles/color.js"
import useWindowSize from "../utils/useWindowSize"

const HeaderButtons = ({
  bigScreen,
  aboutRef,
  contactRef,
  bookRef,
  pressRef,
  imageContainerRef,
  storiesRef,
}) => {
  const [buttonColor, setButtonColor] = useState(colors.brown);
  const [top, setTop] = useState('3.5rem');
  const [bgOpacity, setBgOpacity] = useState(0);
  const [transitionHeight, setTransitionHeight] = useState(null);
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (imageContainerRef && imageContainerRef.current) {
      const currentRef = imageContainerRef.current
      const { offsetHeight, style: { marginBottom } } = currentRef;

      setTransitionHeight(marginBottom ? offsetHeight * 0.55 : offsetHeight);
    }
  }, [imageContainerRef, windowWidth]);

  useScrollPosition(({ currPos }) => {
    const { y } = currPos;
    if (y < -(transitionHeight - 55)) {
      setButtonColor(colors.brown);
      setTop('0');
      setBgOpacity(0.5);
    } else if (y > -(transitionHeight - 55) && y < -20 ) {
      setButtonColor('transparent');
      setBgOpacity(0);
    } else {
      setButtonColor(colors.brown);
      setTop('3.5rem');
      setBgOpacity(0);
    }
  }, [transitionHeight]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: bigScreen ? 'center' : 'flex-start',
        justifyContent: bigScreen ? 'flex-start' : 'space-around',
        color: bigScreen ? colors.brown : buttonColor,
        height: bigScreen ? '78.875px' : 'auto',
        paddingLeft: bigScreen ? '5rem' : 0,
        flexGrow: 1,
        top: bigScreen ? 0 : top,
        position: bigScreen ? 'relative' : 'fixed',
        width: bigScreen ? 'auto' : '100vw',
        zIndex: 11,
        transition: '0.2s  ease',
        ...!bigScreen && {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          backgroundColor: `rgba(242, 228, 203, ${bgOpacity})`
        }
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: bigScreen ? colors.sand : 'transparent',
          opacity: bigScreen ? 0.5 : 0,
          zIndex: -1,
        }}
      />
      <div
        onClick={() => aboutRef.current &&
          aboutRef.current.scrollIntoView({ behavior: "smooth" })
        }
        className="nav-link"
        style={{
          marginRight: bigScreen ? '2.5rem' : 0,
          borderBottom: `1.5px solid`,
          cursor: 'pointer'
        }}
      >
        About
      </div>
      <div
        onClick={() => bookRef.current &&
          bookRef.current.scrollIntoView({ behavior: "smooth" })
        }
        className="nav-link"
        style={{
          marginRight: bigScreen ? '2.5rem' : 0,
          borderBottom: `1.5px solid`,
          cursor: 'pointer'
        }}
      >
        Book
      </div>
      <div
        onClick={() => storiesRef.current &&
          storiesRef.current.scrollIntoView({ behavior: "smooth" })
        }
        className="nav-link"
        style={{
          marginRight: bigScreen ? '2.5rem' : 0,
          borderBottom: `1.5px solid`,
          cursor: 'pointer'
        }}
      >
        Writing
      </div>
      <div
        onClick={() => pressRef.current &&
          pressRef.current.scrollIntoView({ behavior: "smooth" })
        }
        className="nav-link"
        style={{
          marginRight: bigScreen ? '2.5rem' : 0,
          borderBottom: `1.5px solid`,
          cursor: 'pointer'
        }}
      >
        Press
      </div>
      <div
        onClick={() => contactRef.current &&
          contactRef.current.scrollIntoView({ behavior: "smooth" })
        }
        className="nav-link"
        style={{
          marginRight: bigScreen ? '2.5rem' : 0,
          borderBottom: `1.5px solid`,
          cursor: 'pointer'
        }}
      >
        Contact
      </div>
    </div>
  );
}

const PureIndexPage = ({ data }) => {
  const { windowWidth } = useWindowSize();
  const bigScreen = windowWidth > 950;
  const imageContainerRef = useRef(null);

  const image = data.placeholderImage
    ? data.placeholderImage.childImageSharp.resize
    : null

  useEffect(() => {
    const html = document.querySelector('html');
    html.style.overflow = bigScreen ? 'hidden' : 'visible';
    return function remove() {
      html.style.overflow = 'visible';
    }
  }, [bigScreen]);
  const aboutRef = useRef(null);
  const bookRef = useRef(null);
  const pressRef = useRef(null);
  const storiesRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <Layout>
      <SEO
        image={image}
        title="Clare Sestanovich"
      />
      <header
        style={{
          position: 'absolute',
          background: `transparent`,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          zIndex: 10,
          flexDirection: bigScreen ? 'row' : 'column',
        }}
      >
        <div
          style={{
            width: bigScreen ? 500 : '100vw',
            display: `flex`,
            justifyContent: `center`,
          }}
        >
          <h1
            style={{
              fontWeight: `bold`,
            }}
          >
            <a
              style={{
                color: colors.brown,
                textDecoration: `none`,
              }}
            >
              Clare Sestanovich
            </a>
          </h1>
        </div>
        {bigScreen && <HeaderButtons
          bigScreen={bigScreen}
          aboutRef={aboutRef}
          bookRef={bookRef}
          contactRef={contactRef}
          pressRef={pressRef}
          storiesRef={storiesRef}
        />}
      </header>
      <div
        style={{
          display: `flex`,
          justifyContent: `flex-start`,
          flexDirection: bigScreen ? 'row' : 'column',
          width: `100vw`,
          height: bigScreen ? `100vh` : 'auto',
        }}
      >
        {!bigScreen && (
          <HeaderButtons
            bigScreen={bigScreen}
            aboutRef={aboutRef}
            bookRef={bookRef}
            contactRef={contactRef}
            pressRef={pressRef}
            storiesRef={storiesRef}
            imageContainerRef={imageContainerRef}
          />
        )}
        <div
          style={{
            height: bigScreen ? `100vh` : `auto`,
            minWidth: '100vw',
          }}
        >
          <div
            ref={imageContainerRef}
            style={{
              position: bigScreen ? `absolute` : 'relative',
              left: 0,
              height: bigScreen ? `100vh` : `auto`,
              width: bigScreen ? '500px' : '100vw',
              ...(windowWidth < 950 && windowWidth > 450) && {
                marginTop: '-25%',
                marginBottom: '-25%',
              }
            }}
          >
            <ClareImage bigScreen={bigScreen} />
          </div>
        </div>
        <div
          style={{
            position: bigScreen ? 'absolute' : 'relative',
            padding: bigScreen ? `7.5rem 5rem 5rem 5rem` : '0 2.5rem 2.5rem 2.5rem',
            color: colors.brown,
            fontSize: '1.25rem',
            backgroundColor: colors.sand,
            overflow: bigScreen ? 'scroll' : 'hidden',
            ...bigScreen && {
              top: 0,
              right: 0,
              bottom: 0,
              left: `${Math.min((windowWidth - 500), 500)}px`,
              maxWidth: `${Math.max((windowWidth - 500), 500)}px`,
            },
          }}
        >
          <div
            ref={aboutRef}
            style={{ position: bigScreen ? 'absolute' : 'relative', top: 0, height: '3.5rem' }}
          />
          <About bigScreen={windowWidth > 600} />
          <div
            ref={bookRef}
            style={{
              margin: bigScreen ? '5rem 5rem 0' : '2rem 2rem 0',
              height: bigScreen ? '5rem' : '2rem',
              borderTop: `2px ${colors.brown} solid`,
              opacity: 0.5
            }}
          />
          <BookStuff bigScreen={windowWidth > 600} />
          <div
            ref={storiesRef}
            style={{
              margin: bigScreen ? '5rem 5rem 0' : '2rem 2rem 0',
              height: bigScreen ? '5rem' : '2rem',
              borderTop: `2px ${colors.brown} solid`,
              opacity: 0.5
            }}
          />
          <Writing bigScreen={windowWidth > 600} />
          <div
            ref={pressRef}
            style={{
              margin: bigScreen ? '5rem 5rem 0' : '2rem 2rem 0',
              height: bigScreen ? '5rem' : '2rem',
              borderTop: `2px ${colors.brown} solid`,
              opacity: 0.5
            }}
          />
          <Press bigScreen={windowWidth > 600} />
          <div
            ref={contactRef}
            style={{
              margin: bigScreen ? '5rem 5rem 0' : '2rem 2rem 0',
              height: bigScreen ? '5rem' : '2rem',
              borderTop: `2px ${colors.brown} solid`,
              opacity: 0.5
            }}
          />
          <Contact bigScreen={bigScreen} />
        </div>
      </div>
      <footer
        style={{
          zIndex: 2,
          color: bigScreen ? colors.sand : colors.brown, // '#dbc093',
          position: bigScreen ? `fixed` : 'absolute',
          bottom: 0,
        }}
      >
        <div
          style={{
            width: bigScreen ? 500 : '100vw',
            padding: `1.45rem ${bigScreen ? '1.0875rem' : 0}`,
            display: `flex`,
            justifyContent: `center`,
          }}
        >
          © {new Date().getFullYear()} Clare Sestanovich
          </div>
      </footer>
    </Layout>
  )
};

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ClareShare.jpeg" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  `)

  return <PureIndexPage {...props} data={data} />
}

export default IndexPage