import React from "react";
import moment from "moment";


const pressList = [
  {
    date: "1.6.2021",
    link: "https://lithub.com/lit-hubs-most-anticipated-books-of-2021/",
    outlet: "Lit Hub",
    title: "Most Anticipated",
  },
  {
    outlet: "The Millions",
    title: "Most Anticipated list",
    date: "1.10.2021",
    link: "https://themillions.com/2021/01/most-anticipated-the-great-first-half-2021-book-preview.html"
  },
  {
    outlet: "Entertainment Weekly",
    title: "Best Debuts of the Summer",
    date: "5.20.2021",
    link: "https://ew.com/books/best-debuts-read-summer/"
  },
  {
    outlet: "Vulture",
    title: "Summer List",
    date: "5.25.2021",
    link: "https://www.vulture.com/article/summer-books-preview-2021.html"
  },
  {
    outlet: "Entertainment Weekly",
    title: "Books to Read This June",
    date: "5.28.2021",
    link: "https://ew.com/books/best-books-to-read-june-2021/?slide=f27dfe97-a025-4120-8c93-3fff39fcbac6#f27dfe97-a025-4120-8c93-3fff39fcbac6"
  },
  {
    outlet: "Refinery29",
    title: "Summer Reading list",
    date: " 6.1.2021",
    link: "https://www.refinery29.com/en-us/2021/06/10495363/best-summer-books-2021#slide-15"
  },
  {
    outlet: "Esquire",
    title: "Summer roundup",
    date: " 6.4.2021",
    link: "https://www.esquire.com/entertainment/books/g36595766/best-summer-books-2021/"
  },
  {
    outlet: "Lit Hub",
    title: "Short Story roundup",
    date: " 6.4.2021",
    link: "https://lithub.com/10-short-story-collections-to-read-this-summer/"
  },
  {
    outlet: "Lit Hub",
    title: "Interview",
    date: " 6.8.2021",
    link: "https://lithub.com/lit-hub-asks-5-authors-7-questions-no-wrong-answers-18/"
  },
  {
    outlet: "Lit Hub",
    title: "Ultimate Summer Reading List",
    date: "6.10.2021",
    link: "https://lithub.com/the-ultimate-summer-2021-reading-list/"
  },
  {
    outlet: "Wall Street Journal",
    title: "Summer Books Feature",
    date: "6.16.2021",
    link: "https://www.wsj.com/articles/the-best-books-to-read-in-summer-2021-11623841252"
  },
  {
    outlet: "New York Times Books Review",
    title: "Debut Stories",
    date: "6.25.2021",
    link: "https://www.nytimes.com/2021/06/25/books/review/rock-eaters-brenda-peynado-shokos-smile-choi-eunyoung-clare-sestanovich-objects-of-desire.html"
  },
  {
    outlet: "The Paris Review",
    title: "The Momentum of Living",
    date: "6.29.2021",
    link: "https://www.theparisreview.org/blog/2021/06/29/the-momentum-of-living-an-interview-with-clare-sestanovich/"
  },
  {
    outlet: "The Observer",
    title: "A Parade of Great Pretenders",
    date: "7.4.2021",
    link: "https://www.theguardian.com/books/2021/jul/04/objects-of-desire-by-clare-sestanovich-review-a-parade-of-great-pretenders"
  },
  {
    outlet: "Burned by Books",
    title: "Podcast Interview",
    date: "9.1.2021",
    link: "https://open.spotify.com/episode/5pJI1LIm3lIvOi5C5bNTRm"
  },
  {
    outlet: "The New York Review of Books",
    title: "Continually Startling News",
    date: "12.16.2021",
    link: "https://www.nybooks.com/articles/2021/12/16/clare-sestanovich-continually-startling-news/"
  },
  {
    outlet: "Vulture",
    title: "National Book Foundation’s Newest ‘5 Under 35’",
    date: "4.13.2022",
    link: "https://www.vulture.com/article/national-book-foundation-5-under-35-2022.html"
  }
].sort((pressA, pressB) => {
  const pressAMoment = moment(pressA, "M.D.YYYY");
  const pressBMoment = moment(pressB, "M.D.YYYY");

  return pressAMoment.isBefore(pressBMoment)
    ? 1
    : -1;
});

const PressItem = ({
  bigScreen,
  link,
  outlet,
  title,
}) => {
  return (
    <div style={{ flexBasis: 1, flexGrow: 1, marginBottom: "1rem", minWidth: "325px" }}>
      <a href={link}>
        <div style={{ display: "inline-block", marginBottom: "0.5rem"}} >
          <span style={{ fontStyle: "Italic", marginRight: "12px" }}>{outlet}</span>
        </div>
        <p style={{ margin: 0, display: bigScreen ? "inline-block" : "block" }}>
          <span>“{title}”</span>
        </p>
      </a>
    </div>
  );
};

const Press = ({ bigScreen }) => {
  return (
    <div style={{ marginBottom: '10rem'}} >
      <h3 style={{ marginBottom: '2rem', opacity: 0.7 }}>Press</h3>
      <div
        style={{
          width: '100%',
        }}
      >
        {pressList.map(event => <PressItem {...event} bigScreen={bigScreen} />)}
      </div>
    </div>
  )
};

export default Press;