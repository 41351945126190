import React from "react"

const Ital = ({
  string
}) => (
  <span style={{ fontStyle: `italic` }}>
    {string}
  </span>
);

export default Ital;