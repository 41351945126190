import React from "react"

import Ital from "../components/ital"
import AmaImage from "../components/amaCover"
import BookImage from "../components/bookCover"
import colors from "../styles/color.js";

const BookStuff = ({ bigScreen }) => (
  <>
    <div>
      <a
        style={{
          display: 'block',
          textDecoration: 'none',
          color: colors.brown,
          marginBottom: '2rem',
        }}
        href="https://bookshop.org/p/books/ask-me-again-clare-sestanovich/20617474?ean=9780593318119"
      >
        <Ital string="Ask Me Again" />
        {' available June 11th 2024 (pre-order '}
        <span style={{ textDecoration: `underline` }}>
          here
        </span>
        {')'}
      </a>
      <div
        style={{
          display: `flex`,
          alignItems: `flex-start`,
          flexDirection: bigScreen ? 'row' : 'column'
        }}
      >
        <a
          href="https://www.penguinrandomhouse.com/books/653534/ask-me-again-by-clare-sestanovich/"
          style={{
            minWidth: bigScreen ? '30%' : '100%',
            boxShadow: '5px 5px 15px -5px rgba(0,0,0,0.49)',
            marginRight: bigScreen ? '2rem' : 0,
            marginBottom: bigScreen ? 0 : '2rem',
            textDecoration: 'none',
          }}
          key="amacover"
        >
          <AmaImage />
        </a>
        <div>
          <p
            style={{
              fontSize: '0.95rem',
              lineHeight: '1.5',
              marginTop: 0,
              marginBottom: '2rem',
            }}
            key="offill"
          >
            {<Ital string="Ask Me Again" />} is a beautifully observed and deeply philosophical novel, which surprises and delights at every turn.
            <br />
            <br />
            — Jenny Offill, author of {<Ital string="Dept. of Speculation" />}
          </p>
          <p
            style={{
              fontSize: '0.95rem',
              lineHeight: '1.5',
              marginTop: 0,
              marginBottom: '2rem',
            }}
            key="garth"
          >
            This beautiful debut novel is wise about intellectual and erotic discovery, disenchantment and loneliness. It’s alert to the small moments of awkwardness and grace that make up the texture of common life; its quiet, tectonic power comes from an awareness of how easily common life can tilt toward catastrophe. Clare Sestanovich is a writer of disarming radiance.
            <br />
            <br />
            — Garth Greenwell, author of {<Ital string="Cleanness" />}
          </p>

          <p
            style={{
              fontSize: '0.95rem',
              lineHeight: '1.5',
              marginBottom: '2rem',
            }}
            key="kleeman"
          >
            Rigorous, intensely observed, and brimming with the sort of elusive revelations that form the heartbeat of a life, Sestanovich's novel debut demonstrates a tremendous gift at rendering the texture of love, faith, and heartbreak with both subtlety and force. In her masterful hands, relationships condense, turn acute, and unfurl with symphonic grace across the individual arcs of characters that you can't help but carry with you long afterward.
            <br />
            <br />
            — Alexandra Kleeman,  author of {<Ital string="Something New Under the Sun" />}
          </p>
        </div>
      </div>
    </div>
    <div>
      <a
        style={{
          display: 'block',
          textDecoration: 'none',
          color: colors.brown,
          marginBottom: '2rem',
        }}
        href="https://bookshop.org/books/objects-of-desire-stories/9780593318096"
      >
        {'Praise for '}
        <Ital string="Objects of Desire" />
        {'  (order '}
        <span style={{ textDecoration: `underline` }}>
          here
        </span>
        {')'}
      </a>
      <div
        style={{
          display: `flex`,
          alignItems: `flex-start`,
          flexDirection: bigScreen ? 'row' : 'column'
        }}
      >
        <a
          href="https://www.penguinrandomhouse.com/books/653533/objects-of-desire-by-clare-sestanovich/"
          style={{
            minWidth: bigScreen ? '30%' : '100%',
            boxShadow: '5px 5px 15px -5px rgba(0,0,0,0.49)',
            marginRight: bigScreen  ? '2rem' : 0,
            marginBottom: bigScreen ? 0 : '2rem',
            textDecoration: 'none',
          }}
        >
          <BookImage />
        </a>
        <div>
          <p
            style={{
              fontSize: '0.95rem',
              lineHeight: '1.5',
              marginTop: 0,
              marginBottom: '2rem',
            }}
          >
            Clare Sestanovich’s stories compelled me like gravity, and offered sharp, surprising, singular bursts of grace. Her characters are observed with wry, prism-gazed tenderness; sketched deftly and persuasively with just a few perfect strokes. The details go off like bombs. These stories know strange, important truths about what it feels like to be alive.
            <br />
            <br />
            — Leslie Jamison,  author of {<Ital string="The Recovering" />} and {<Ital string="The Empathy Exams" />}
          </p>
          <p
            style={{
              fontSize: '0.95rem',
              lineHeight: '1.5',
              marginTop: 0,
              marginBottom: '2rem',
            }}
          >
            Sestanovich’s elegant prose takes seriously the quiet unrest that can ravage a life, and makes room for the pleasure and discovery that can be found in that ruin.
            <br />
            <br />
            — Raven Leilani, author of {<Ital string="Luster" />}
          </p>
        </div>
      </div>
    </div>
  </>
);

export default BookStuff;