import React from "react"

import Ital from "../components/ital"

const About = ({ bigScreen }) => (
  <div>
    <p
      style={{
        fontSize: '0.95rem',
        lineHeight: '1.5',
        marginBottom: '2rem',
      }}
    >
      CLARE SESTANOVICH, named a “5 Under 35” honoree by the National Book Foundation in 2022, is the author of the debut novel, <Ital string="Ask Me Again" />. Her story collection, <Ital string={"Objects of Desire"} />, was published by Knopf in 2021, and was a finalist for the PEN/Robert W. Bingham Prize. Her fiction has appeared in publications such as <Ital string="The New Yorker" />, <Ital string="The Paris Review" />, and <Ital string="Harper’s" />. She lives in Brooklyn.
    </p>
  </div>
);

export default About;