import React from "react"

const  Header = ({
  text
}) => (
  <h4
    style={{
      marginBottom: '0.5rem',
    }}
  >
    {text}
  </h4>
)

const Contact = () => (
  <div style={{ marginBottom: '25rem' }}>
    <h3 style={{ marginBottom: '2rem', opacity: 0.7 }}>Contact</h3>
    <div>
      <Header text="Publicity" />
      <p>
        Amy Hagedorn<br />
        <a href="mailto:ahagedorn@penguinrandomhouse.com">
          ahagedorn@penguinrandomhouse.com
        </a>
      </p>
    </div>

    <div>
      <Header text="Literary agent" />
      <p>
        Bill Clegg<br />
        <a href="mailto:bill@thecleggagency.com">
          bill@thecleggagency.com
        </a>
      </p>
    </div>

    <div>
      <Header text="Twitter" />
      <p>
        {'Follow me '}
        <a
          href="https://twitter.com/csestanovich"
          style={{
            paddingBottom: '3px',
            paddingLeft: '4px',
          }}
        >
          @<span style={{ borderBottom: '1px solid' }}>csestanovich</span>
        </a>
      </p>
    </div>
  </div>
);

export default Contact;