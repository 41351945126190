import React from "react"

import Ital from "../components/ital"
import DriftCover from "../components/theDrift"
import HarpersCover from "../components/harpersCover"
import ParisReview from "../components/parisReview"
import NewYorker from "../components/newYorker"
import NewYorker2 from "../components/newYorker2"
import NewYorker3 from "../components/newYorker3"
import NewYorker4 from "../components/newYorker4"
import NewYorker5 from "../components/newYorker5"

const essaysAndCriticismData = [
  {
    title: "Nothing Happens: On Literary Criticism Today",
    outlet: "The Drift",
    year: "2022",
    link: "https://www.thedriftmag.com/nothing-happens/"
  },
  {
    title: "Back to the Wall: On Marlen Haushofer",
    outlet: "The Baffler",
    year: "2022",
    link: "https://thebaffler.com/latest/back-to-the-wall-sestanovich"
  },
  {
    title: "Interior Design: On Claire-Louise Bennett’s “Checkout 19”",
    outlet: "Los Angeles Review of Books",
    year: "2022",
    link: "https://lareviewofbooks.org/article/interior-design-on-claire-louise-bennetts-checkout-19/"
  },
  {
    title: "The Joy of Crossing Paths With Strangers",
    outlet: "The New Yorker",
    year: "2021",
    link: "https://www.newyorker.com/culture/dept-of-returns/the-joy-of-crossing-paths-with-strangers"
  },
  {
    title: "The Unreality of Coming of Age",
    outlet: "Lithub",
    year: "2017",
    link: "https://lithub.com/the-unreality-of-coming-of-age/"
  }
];

const Story = ({
  bigScreen,
  date,
  img,
  publication,
  title,
  url,
}) => (
  <div
    key={title}
    style={{
      marginBottom: '2rem',
      flexBasis: 1,
      flexGrow: 1,
      marginRight: '0.5rem'
    }}
  >
    <a
      href={url}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: bigScreen ? 'flex-start' : 'center',
        flexDirection: bigScreen ? 'row' : 'column',
      }}
    >
      <div
        style={{
          minWidth: bigScreen ? '10rem' : '100%',
          ...bigScreen && { marginRight: '2rem' }
        }}
      >
        {img}
      </div>
      <div
        style={{
          marginTop: '1.5rem',
          textAlign: bigScreen ? 'left' : 'center',
        }}
      >
        <div style={{ marginBottom: '0.85rem', whiteSpace: 'nowrap' }}>
          “{title}”
        </div>
        <div
          style={{
            marginBottom: '0.75rem',
            fontSize: '1rem',
            fontFamily: 'Raleway',
            whiteSpace: 'nowrap'
          }}
        >
          <Ital string={publication} />
        </div>
        <div
          style={{
            fontSize: '0.9rem',
            marginBottom: '1.25rem',
            fontFamily: 'Raleway',
            whiteSpace: 'nowrap'
          }}
        >
          {date}
        </div>
      </div>
    </a>
  </div>
)

const Writing = ({
  bigScreen,
}) => (
  <div>
    <h3 style={{ marginBottom: '2rem', opacity:  0.7 }}>Stories</h3>
    <div
      style={{
        display: 'flex',
        flexDirection: bigScreen ? 'row' : 'column',
        alignItems: bigScreen ? 'flex-start' : 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      <Story
        bigScreen={bigScreen}
        img={<NewYorker5 />}
        date="November 2023"
        publication="The New Yorker"
        title="Our Time Is Up"
        url="https://www.newyorker.com/magazine/2023/11/13/our-time-is-up-fiction-clare-sestanovich"
      />
      <Story
        bigScreen={bigScreen}
        img={<NewYorker4 />}
        date="January 2023"
        publication="The New Yorker"
        title="Different People"
        url="https://www.newyorker.com/magazine/2023/01/30/different-people"
      />
      <Story
        bigScreen={bigScreen}
        img={<NewYorker3 />}
        date="August 2022"
        publication="The New Yorker"
        title="You Tell Me"
        url="https://www.newyorker.com/magazine/2022/08/01/you-tell-me"
      />
      <Story
        bigScreen={bigScreen}
        img={<DriftCover />}
        date="May 2021"
        publication="The Drift"
        title="Objects of Desire"
        url="https://www.thedriftmag.com/objects-of-desire/"
      />
      <Story
        bigScreen={bigScreen}
        img={<NewYorker2 />}
        date="April 2021"
        publication="The New Yorker"
        title="Separation"
        url="https://www.newyorker.com/magazine/2021/04/12/separation"
      />
      <Story
        bigScreen={bigScreen}
        img={<HarpersCover />}
        date="July 2020"
        publication="Harper’s"
        title="Annunciation"
        url="https://harpers.org/archive/2020/07/annunciation-clare-sestanovich/"
      />
      <Story
        bigScreen={bigScreen}
        img={<ParisReview />}
        date="Spring 2020"
        publication="The Paris Review"
        title="By Design"
        url="https://www.theparisreview.org/fiction/7510/by-design-clare-sestanovich"
      />
      <Story
        bigScreen={bigScreen}
        img={<NewYorker />}
        date="December 2019"
        publication="The New Yorker"
        title="Old Hope"
        url="https://www.newyorker.com/magazine/2019/12/09/old-hope"
      />
    </div>
    <h3 style={{ marginBottom: '2rem', opacity: 0.7 }}>Essays and Criticism</h3>
    {essaysAndCriticismData.map(obj => (
      <div style={{ marginBottom: "1.75rem" }}>
        <a href={obj.link}>
          <div style={{ marginBottom: "0.8rem" }}>{obj.title}</div>
          <div style={{ fontSize: '1rem' }}>
            <Ital string={`${obj.outlet}, `} /><span style={{ paddingLeft: "0.25rem" }}>{obj.year}</span>
          </div>
        </a>
      </div>
    ))}
  </div>
);

export default Writing;